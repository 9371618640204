

.clip-text:before,
.clip-text-after {
    position: absolute;
    content: '';
}

/* Background */
.clip-text:before {
    z-index: -2;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-image: inherit;
    background-position: center;
    background-repeat: no-repeat;
}

/* Text Background (black zone) */
.clip-text-after {
    position: absolute;
    z-index: -1;
    top: .3em;
    right: .3em;
    bottom: .3em;
    left: .3em;
}

/* Change the background position to display letter when the black zone isn't here */
.clip-text--no-textzone:before {
  background-position: -.75em 0;
}
.clip-text--no-textzone:after {
  content: none;
}

.clip-text {
  -webkit-text-stroke: 1px white;
  overflow-wrap: break-word;
  position: relative;
  text-align: center;
  display: inline-block;
  /* Color fallback */
  color: #fff;
  -webkit-text-fill-color: transparent;
  background-position: center;
  font-weight: 1000;
  letter-spacing: -10px;
  margin: .5em;
  padding-top: .5em;
  padding-bottom: .3em;
}
.contacto {
  a {
    outline: none;
    text-decoration: none;
    padding: 2px 1px 0;
    font-weight: inherit !important;
  }

  a:link {
    color: white;
  }
  a:visited {
    color:lightblue;
  }

  a:hover {
    border-bottom: 1px solid;
    background: rgba($color: lightblue, $alpha: 0.3);
  }
}

@media screen and (max-width: 512px) {
  .clip-text {
    font-size: 70px;
    line-height: 70px;
    max-width: 100vw;
    padding-left: .5em;
    padding-right: .5em;
    background-size: cover;
  }
  .text-contenido {
    line-height: 1.25em;
    margin-bottom: 15px;
    font-size: 1.04rem;
  }

  .text-contenido-chico {
    line-height: 1.05em;
    margin-bottom: 15px;
    font-size: 0.9rem;
  }
  .contenido-row {
    flex-direction: column;
  }
  .clip-text:before {
    background-size: cover;
  }
  .clip-text-smaller {
    font-size: 60px;
  }
}

@media screen and (min-width: 768px) {
  .clip-text {
    font-size: 120px;
    line-height: 100px;
    max-width: 110vw;
    padding-left: .6em;
    padding-right: .6em;
  }

  .text-contenido {
    line-height: 1.45em;
    margin-bottom: 15px;
    font-size: 1.0625rem;
  }

  .text-contenido-chico {
    line-height: 1.45em;
    margin-bottom: 15px;
    font-size: 1.0625rem;
  }
}

@media screen and (min-width: 513px) and (max-width: 768px) {
  .clip-text {
    font-size: 100px;
    line-height: 70px;
    max-width: 100vw;
    padding-left: .5em;
    padding-right: .5em;
  }
  .text-contenido {
    line-height: 1.25em;
    margin-bottom: 15px;
    font-size: 1.04rem;
  }

  .text-contenido-chico {
    line-height: 1.05em;
    margin-bottom: 15px;
    font-size: 0.9rem;
  }
  .contenido-row {
    flex-direction: column;
  }
}

@media screen and (min-width: 768px) and (max-width: 992px) {
  .text-contenido-chico {
    line-height: 1.15em;
    margin-bottom: 15px;
    font-size: 1.1rem;
  }
}
