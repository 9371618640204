.img-custom {
    border-radius: $border-radius-sm;
}

@media screen and (max-width: 768px) {
  .img-custom {
    max-height: 500px;
    position: relative;
    right: 50%
  }
}

@media screen and (min-width: 768px) {
  .img-custom {
    max-width: 100%;
  }
}
.img-raised{
    box-shadow: $box-shadow-raised;
}

.img-center {
  display: block;
  margin-right: auto;
  margin-left: auto;
}
